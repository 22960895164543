import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function DemoAppTerms({
  children
}) {
  return <LegalDoc title="Fidel API Demo App Terms of Service" updated="14 Oct 2022">{children}</LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Fidel API operates a service that allows you to obtain an experiential and live demonstration of Transaction Stream API (Fidel API’s personal finance and expense management transaction monitoring products and services) (`}<strong parentName="p">{`“Fidel API Services”`}</strong>{`) from a cardholder perspective via a web app (`}<strong parentName="p">{`“Demo App”`}</strong>{`) (together with the Fidel API Services delivered via the Demo App, the `}<strong parentName="p">{`“Demo App Service”`}</strong>{`). `}</p>
    <p>{`The Demo App Service is delivered by Enigmatic Smile (`}<strong parentName="p">{`"Fidel API"`}</strong>{`, `}<strong parentName="p">{`"we"`}</strong>{`, `}<strong parentName="p">{`"us"`}</strong>{`) (`}<a parentName="p" {...{
        "href": "https://fidelapi.com/"
      }}>{`https://fidelapi.com/`}</a>{`), a company registered in England and Wales (company number 08869163) with our registered office at Swift House Ground Floor, 18 Hoffmanns Way, Chelmsford, Essex, England, CM1 1GU.`}</p>
    <p>{`These Demo App terms of service (`}<strong parentName="p">{`"Terms"`}</strong>{`) apply to you and your use of the Demo App Service. `}</p>
    <p>{`Please read these Terms carefully before you download or install the Demo App or start to use the Demo App Service. By using the Demo App or the Demo App Service at any time, you confirm that you agree to these Terms as they are in force at that time. If you do not agree to these Terms, you are prohibited from downloading or installing the Demo App and using the Demo App Service. `}</p>
    <p>{`You acknowledge and agree that your use of the Demo App Service is for the purposes of trial and demonstration of the Fidel API Services in relation to your interest in the Fidel API Services for purchase only (`}<strong parentName="p">{`“Purpose”`}</strong>{`), whether as an individual, or on behalf of your employer or client, and the Demo App Service, nor any data derived therefrom, is not permitted for use for any other purpose including, but not exclusively, any commercial or end-user cardholder-facing applications. If you are evaluating on behalf of your employer or client, your use of the Demo App Service is also subject to agreement between Fidel API and your employer/client.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Definitions`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`In these Terms, the following words and expressions have the following meaning:`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Card Networks:`}</strong>{` means Visa or any other payment processor that facilitates card payment transactions between issuing banks who are authorized by you to provide your transaction data to Fidel API;`}</li>
              <li parentName="ul"><strong parentName="li">{`Eligible Payment Card:`}</strong>{` means a Payment Card which is eligible to become a Linked Card as determined in accordance with paragraph 5 (currently only Visa cards can be Eligible Payment Cards); `}</li>
              <li parentName="ul"><strong parentName="li">{`Linked Card:`}</strong>{` means an Eligible Payment Card that you enroll with the Fidel API Service for use with the Demo App Service; `}</li>
              <li parentName="ul"><strong parentName="li">{`Payment Card`}</strong>{` means a credit or debit card issued by an issuing bank; `}</li>
            </ul>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`How to contact us and how we will contact you`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`You can contact us via email at `}<a parentName="li" {...{
              "href": "mailto:demo-app@fidelapi.com."
            }}>{`demo-app@fidelapi.com.`}</a>{` We will contact you at the email address you provide at registration.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Information about you and your privacy`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Your privacy is important to us. You should read our Privacy Policy to understand how we collect, use and share information about you available at: `}<a parentName="li" {...{
              "href": "https://fidelapi.com/legal/privacy/"
            }}>{`https://fidelapi.com/legal/privacy/`}</a>{`.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Purpose`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`You acknowledge and agree that you are adding your Eligible Payment Cards and using the Demo App Service solely for the Purpose. You must be 18 years or older or of legal age in your territory in order to be eligible to use the Fidel API Service.`}</li>
          <li parentName="ol">{`You acknowledge and agree that if at any time, Fidel API in its sole and absolute discretion may terminate your access to the Fidel API Service or the Demo App Service. `}</li>
          <li parentName="ol">{`If you are evaluating the Demo App Service on behalf of your employer or client, you acknowledge and agree that you have clearly identified your employer or client to Fidel API, that your employer or client has entered into separate terms and conditions governing the use of the Demo App Service with Fidel API, and that you are duly authorized to trial the Demo App Services by your employer or client.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Registration and Your Information`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`To use the Demo App Services, you will need to create an account within the Demo App using your email address. We will use your email address with a third party authentication service to verify Demo App account ownership. We will send you Demo App Service-related messages to this email address.`}</li>
          <li parentName="ol">{`You acknowledge and agree that your login and authentication details relating to the Demo App Service and accounts are confidential information and you will not share this information with any third party without the express written and prior consent of Fidel API.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Card eligibility and linking`}</strong>{` `}
        <ol parentName="li">
          <li parentName="ol">{`Card Eligibility: Only Eligible Payment Cards may become Linked Cards. Please note that not all Card Network cards are Eligible Payment Cards. Currently, only Visa cards can be Eligible Payment Cards, but across all typical Card Networks, the Payment Cards not eligible to become Linked Cards are Visa, MasterCard, and American Express Corporate cards, Visa, MasterCard, and American Express Purchasing cards, non-reloadable prepaid cards, government-administered prepaid cards (including EBT cards), healthcare (including Health Savings Account (HSA) or Flexible Spending Account (FSA) or insurance prepaid cards, Visa Buxx, and Visa-branded, MasterCard-branded, and American Express-branded cards whose transactions are not processed through the Visa payment system, MasterCard payment system, and/or American Express payment system, and any other type of card notified to you by Fidel from time to time.`}</li>
          <li parentName="ol">{`Fidel API and the Card Networks may in their sole and absolute discretion decide whether a Payment Card is eligible to become a Linked Card.`}</li>
          <li parentName="ol">{`Depending on the territory your registered debit card transaction may be processed as a 'credit' (i.e.  authorized with signature and not a PIN) transaction to make sure the transaction can be monitored.`}</li>
          <li parentName="ol">{`Linking cards: You may link three (3) Eligible Payment Card to the Demo App Service to become Linked Cards. You will need to verify your identity when linking a card via the Demo App. You are prohibited from linking an Eligible Payment Card that is not registered in your name.`}</li>
          <li parentName="ol">{`You are responsible for entering the correct information when setting up your Linked Card, and neither will we nor the Card Networks be responsible for any error in this respect, nor are we or the Card Networks responsible if for any technical or other reason, you are unable to link your Payment Card. `}</li>
          <li parentName="ol">{`Linking/Un-linking an Eligible Payment Card: A Linked Card shall remain a Linked Card until (a) you un-link it; or (b) your Linked Card is automatically un-linked 30 days after you opened your Demo App account; or (c) you delete your Demo App account, whichever comes earlier. You can un-link an Eligible Payment Card by clicking the ‘unlink’ button for the relevant Linked Card in the Demo App.`}</li>
          <li parentName="ol">{`You acknowledge that the Card Networks may be unable to monitor every transaction made with your Linked Cards including PIN-based purchases on debit cards, purchases you initiate through identification technology that substitutes for a PIN, payments made through other payment methods (such as a digital wallet or a third party payment app, where you may choose your Eligible Payment Card as a funding source but you do not present your Eligible Payment Card directly to the merchant), payments of existing balances, balance certain Card Network payment systems, and that these transactions are not eligible. If you register a debit card, your transaction must be processed as a ‘credit’ (i.e., signature) transaction to make sure the transaction can be monitored. Do not use a Personal Identification Number (PIN) when paying for your purchases with your enrolled card if you want the transaction to be available for view in the Demo App.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Collection and use of transaction data`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`By agreeing to these Terms and checking the appropriate box at the time of enrolling the Linked Card(s), you are providing the following authorization (for the entire duration of your use of the Demo App Service) to enable the use of data (including your registered card identifier and transaction data (merchant, transaction date/time, amount and other transaction elements (currency, Payment Card Network, etc.) from your Linked Card(s) with the Fidel API Services for the Purpose, including for:`}
 <ol type="a">
     <li>Fidel API to share your Linked Card information with the Card Networks so the applicable Card Network(s) is or are informed of the registration of the Linked Card as a part of the Fidel API Service;</li>
     <li>Fidel API to use your Linked Card’s transaction data to provide you with immediate notifications of transactions on your Linked Card (and other transaction-related events) and display relevant transaction data in the Demo App;</li>
     <li>Fidel API to use and share your Linked Card information with the Card Networks as part of the Demo App Services;</li>
     <li>Card Network(s) to monitor your transactions on your Linked Card(s) and to share transaction information with Fidel API in relation to the Fidel API Services;</li> 
     <li>Fidel API to collect and analyze analytics data relating to your use of the Demo App Services (analytics data only includes aggregated information such as volume of transactions and number of logins to the Demo App account; for the avoidance of doubt, analytics data does not include any specific transaction-level data) (<strong>“Analytics Data”</strong>); and</li>
     <li>Fidel API to share your transaction data received from Card Networks with governmental authorities to comply with lawful requests.</li>
 </ol>
          </li>
          <li parentName="ol">{`If you un-link a Linked Card, the Demo App Services will be available for transactions you made with the Linked Card up to the time at which you un-link the Linked Card, provided that your Demo App account is still active. The Demo App Services will be available for this data until you `}
 <ol type="a">
     <li>delete your Demo App account; or</li> 
     <li>your Demo App account is terminated by Fidel API in accordance with these Terms.</li>
 </ol> 
 <br />
 Analytics Data will be held and processed by Fidel API in accordance with our Privacy Policy.
          </li>
          <li parentName="ol">{`You may opt-out of the monitoring and collection of your transaction data by un-linking your Linked Card by following the process for un-linking set out at paragraph 6.6.`}</li>
          <li parentName="ol">{`Any relevant Card Network(s) shall have the right to enforce the terms of this paragraph 7.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Our responsibility to you`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`As this is a free trial, the Demo App, Demo App Service, and Fidel API Service are each provided on an "AS IS" and "AS AVAILABLE" basis, and we and the Card Networks expressly exclude all warranties of any kind, whether express or implied, including (but not limited to) the implied warranties of merchantability, fitness for a particular purpose and non-infringement, to the extent permitted by law. `}</li>
          <li parentName="ol">{`Certain features may rely on your device or networks and connections that are beyond our control. We shall therefore not be liable or responsible for any failure to perform, or delay in performance, of any of our obligations to you which is caused by events outside our reasonable control.`}</li>
          <li parentName="ol">{`As this is a free trial, we and the Card Networks are not responsible for any losses or damages of any kind (whether indirect, special, incidental, punitive or consequential) that arise out of or in connection with your download or installation of the Demo App or your use of the Demo App Services or Fidel Services whether a breach of these Terms or caused by our or the Card Networks’ negligence. `}</li>
          <li parentName="ol">{`Nothing in these Terms excludes or limits any (a) statutory rights which may apply to your use of the Fidel API Service which cannot be excluded, restricted or modified by contract, or (b) any liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors or for fraud or fraudulent misrepresentation.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Access and Interference`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`You agree that you will not`}
            <ol parentName="li">
              <li parentName="ol">{`use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor the Demo App Services or any portion of the Demo App Services;`}</li>
              <li parentName="ol">{`use or attempt to use any engine, software, tool, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search the Demo App Services, other than the search engines and search agents available through the Demo App Services.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`If you are evaluating the Demo App Services on behalf of your employer or client, you, on behalf of your employer or client, hereby indemnify Fidel API, its officers, directors, shareholders, employees, the Card Networks, and suppliers from and against all claims, suits, proceedings, losses, liabilities, and expenses, whether in tort, contract, or otherwise, that arise out of or relate (including, but not limited to, legal fees) in whole or arising from your use of the Demo App Services or Fidel Services or your breach of these Terms.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Termination`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`We may suspend or end your use of the Fidel API Service at any time in accordance with paragraph 4.2 and if: `}
            <ol parentName="li">
              <li parentName="ol">{`you breach any confidentiality obligations to Fidel API that may apply to your interest in the Fidel Services;`}</li>
              <li parentName="ol">{`your Linked Card expires or is deactivated;`}</li>
              <li parentName="ol">{`you have not logged into your Demo App account for 30 days;`}</li>
              <li parentName="ol">{`you breach these Terms in a serious way. `}</li>
            </ol>
          </li>
          <li parentName="ol">{`You may cease your use of the Demo App Services by terminating your account by contacting us as set out at paragraph 2.1. `}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Changes to these Terms`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`We may change these Terms from time to time.  Any changes we may make to these Terms in the future will be posted on Fidel API’s website (`}<a parentName="li" {...{
              "href": "https://fidelapi.com/"
            }}>{`https://fidelapi.com/`}</a>{`). `}</li>
          <li parentName="ol">{`By continuing to use the Fidel API Service following such changes, you agree to be bound by any changes we make. Please review this page frequently to see any updates or changes to these Terms.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Legal stuff`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`All intellectual property in the Demo App Service and all content contained therein (including but not limited to the screen displays, the content, the text, graphics and look and feel of the Demo App Service, the Fidel Service and Fidel API’s website), belongs to us or our licensors. `}</li>
          <li parentName="ol">{`All other trademarks, service marks, company names or logos are the property of their respective holders. Any use by you of these marks, names and logos may constitute an infringement of the holders' rights. We do not warrant that the Demo App Service does not infringe any intellectual property rights of third parties.`}</li>
          <li parentName="ol">{`The invalidity or unenforceability of any provision (in whole or part) of these Terms shall not affect the validity or enforceability of the remaining provisions (in whole or part). `}</li>
          <li parentName="ol">{`The whole or part of any provision which is held by a court of competent jurisdiction to be invalid or unenforceable shall be deemed deleted from these Terms.`}</li>
          <li parentName="ol">{`These Terms are personal to you. You shall not be entitled to assign these Terms in whole or in part to any third party without our prior written consent.`}</li>
          <li parentName="ol">{`We may transfer our rights and obligations under these Terms to another organization. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under these Terms. `}</li>
          <li parentName="ol">{`Any distribution, reprint or electronic reproduction of any content from the Demo App Services in whole or in part for any purpose is expressly prohibited without our prior written consent. You agree not to use, nor permit any third party to use, the Site or the Demo App Services or content in a manner that violates any applicable law, regulation or these Terms.`}</li>
          <li parentName="ol">{`These Terms do not give rise to any rights for any third parties save that the relevant Card Network may enforce these Terms where it is an intended beneficiary.`}</li>
          <li parentName="ol">{`Any failure by us to enforce any of these Terms shall not be a waiver of them or limit the right to subsequently enforce any of these Terms.`}</li>
          <li parentName="ol">{`These Terms shall be governed and construed in accordance with English law.  However, this does not exclude any mandatory legal rights you may have in your country of residence, where we are not allowed to exclude such as a matter of law. `}</li>
          <li parentName="ol">{`The English courts will have jurisdiction, but this does not affect your ability to make a claim in your country of residence where you have this right.`}</li>
        </ol>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      